import axios from 'axios';

export const sendMail = async (to, firstname) => {
  const msg = {
    to: { email: to, name: firstname },
    from: { email: 'welcome@plaqad.com', name: 'PresOfis' },
    sendGridTemplateId: process.env.REACT_APP_EMAIL_TEMPLATE_ID,
    dynamic_template_data: {
      firstname,
      email: to,
    },
    type: 'template',
  };
  var config = {
    method: 'post',
    url: 'https://messagingstage.plaqad.com/v1/api/email/send',
    headers: {
      Authorization: 'Bearer ' + process.env.REACT_APP_EMAIL_KEY,
      'Content-Type': 'application/json',
    },
    data: msg,
  };

  try {
    await axios(config);
  } catch (error) {
    console.log(error);
  }
};

export const sendMailBack = async (email, firstname) => {
  const msg = {
    to: { email: 'welcome@plaqad.com', name: 'PresOfis' },
    from: { email: 'welcome@plaqad.com', name: 'PresOfis' },
    subject: 'New User Alert - Presofis Waitlist',
    // text: `There is a new user - ${firstname} - ${email}`,
    body: `<strong>There is a new user - ${firstname} - ${email}</strong>`,
  };

  try {
    var config = {
      method: 'post',
      url: 'https://messagingstage.plaqad.com/v1/api/email/send',
      headers: {
        Authorization: 'Bearer ' + process.env.REACT_APP_EMAIL_KEY,
        'Content-Type': 'application/json',
      },
      data: msg,
    };
    await axios(config);
  } catch (error) {
    console.log(error);
  }
};
