import React, { useEffect, useState } from 'react';
import Logo from '../images/presofis_plaqad_logo_home.svg';
import Spinner from '../images/spinner.svg';
import Instagram from '../images/instagram.svg';
import Twitter from '../images/twitter.svg';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import firebaseApp from '../utils/firebase';
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
} from 'firebase/firestore';

// Firestore set up
const db = getFirestore(firebaseApp);

// collections
const usersCollection = collection(db, 'users');
const userEmailQuery = (email) =>
  query(usersCollection, where('email', '==', email));

export default function UnsubscribePage() {
  const [isLoading, setIsLoading] = useState(false);
  let [searchParams] = useSearchParams();
  let email = searchParams.get('email');
  const navigate = useNavigate();

  useEffect(() => {
    if (!email) {
      navigate('/');
    }
  }, [email, navigate]);

  const unsubscribeAction = async () => {
    setIsLoading(true);
    try {
      const emailExists = await getDocs(userEmailQuery(email.toLowerCase()));
      if (!emailExists.size) {
        setIsLoading(false);
        return toast('Email does not exist on our list', {
          type: 'error',
        });
      }

      const unsubscribeUser = doc(db, 'users', emailExists.docs[0].id);
      await updateDoc(unsubscribeUser, {
        unsubscribed: true,
      });
      setIsLoading(false);
      toast('You have been unsubscribed', {
        type: 'success',
      });
      return navigate('/');
    } catch (error) {
      setIsLoading(false);
      return toast(error.message, {
        type: 'error',
      });
    }
  };

  return (
    <>
      <div className='hero'>
        <div className='nav'>
          <div className='logo'>
            <img src={Logo} alt='logo' />
          </div>
        </div>
        <div className='unsubscribe'>
          <h1>Unsubscribe from the mailing list</h1>
          <p>You will no longer be able to receive emails from us.</p>

          <div className='hero-inputs one-input-box'>
            <div className='hero-inputs-groups'>
              <input
                disabled
                placeholder='Enter your email address'
                value={email}
                //   onChange={(e) => setEmailValue(e.target.value)}
              />
            </div>

            <button disabled={isLoading} onClick={unsubscribeAction}>
              <span>unsubscribe</span>
              {isLoading && <img src={Spinner} alt='point' />}
            </button>
          </div>
        </div>
      </div>
      <div className='footer no-top'>
        <div className='logo'>
          <img src={Logo} alt='logo' />
        </div>

        <div className='address'>
          <span style={{ fontWeight: 'bold' }}>Nigeria:</span>
          <br />
          32 Community Rd, Allen, Ikeja, Nigeria.
          <br />
          Tel: +234 7000PLAQAD
        </div>
        <br />
        <div className='address'>
          <span style={{ fontWeight: 'bold' }}>United States:</span>
          <br />
          300 Delaware Ave. Suite 210 Wilmington, DE, US 19801.
          <br />
          Tel: +1 302 396 9651
        </div>
        <div className='socials'>
          {/* <a href='/'>
           <img src={Facebook} alt='Facebook' />
         </a> */}
          <a
            href='https://instagram.com/plaqadinc'
            target='_blank'
            rel='noreferrer'>
            <img src={Instagram} alt='Instagram' />
          </a>
          <a
            href='https://twitter.com/plaqadinc'
            target='_blank'
            rel='noreferrer'>
            <img src={Twitter} alt='Twitter' />
          </a>
        </div>

        <div className='copyright'>
          © {new Date().getFullYear()} Plaqad Inc. All Rights Reserved.
        </div>
      </div>
    </>
  );
}
