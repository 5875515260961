import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import './App-mobile.css';
import HomePage from './pages/home';
import UnsubscribePage from './pages/unsubscribe';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='unsubscribe' element={<UnsubscribePage />} />
      </Routes>
      <ToastContainer hideProgressBar theme='colored' />
    </>
  );
}

export default App;
