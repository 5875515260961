// images
import HandPoint from '../images/hand-point.svg';
// import Happy from '../images/happy.svg';
// import Headphone from '../images/headphone.svg';
import Logo from '../images/presofis_plaqad_logo_home.svg';
// import LogoBlue from '../images/logo-blue.svg';
import Rocket from '../images/rocket.svg';
import RoundText from '../images/round-text.svg';
import Smiley from '../images/smiley.svg';
import StarCheck from '../images/star-check.svg';
import StarOutline from '../images/star-outline.svg';
import MonitorYellow from '../images/monitor-yellow.svg';
import LayerBlue from '../images/layer-blue.svg';
import ChartYellow from '../images/chart-yellow.svg';
import StatsupBlue from '../images/statsup-blue.svg';
import StatusBlue from '../images/status-blue.svg';
import StatusYellow from '../images/status-yellow.svg';
import ChartBlue from '../images/chart-blue.svg';
import WaveBlue from '../images/wave-blue.svg';
import TextBlue from '../images/text-blue.svg';
import TaskYellow from '../images/task-yellow.svg';
// import Facebook from '../images/facebook.svg';
import Instagram from '../images/instagram.svg';
import Twitter from '../images/twitter.svg';
import SupriseImg from '../images/suprise.png';
import Spinner from '../images/spinner.svg';
// import BgImg from '../images/presofis_image_home.svg';
// import BgImg1 from '../images/bgImg1.svg';
// import BgImg2 from '../images/bgImg2.svg';
import PhoneImg from '../images/phone.svg';
import TabsImg from '../images/tabs.svg';
import SmileImg from '../images/smile.svg';
import NewsImg from '../images/news.svg';
import LaptopImg from '../images/laptop.svg';
import BarchartImg from '../images/barchart.svg';
import PlaqadBgHero from '../images/bg-hero.png';
import PlaqadBgPhone from '../images/phoneHero.png';
import LadyImg from '../images/lady.png';
import BannerImg from '../images/card.png';

import AnchorLink from 'react-anchor-link-smooth-scroll';
// import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  getDoc,
  doc,
  setDoc,
} from 'firebase/firestore';

import { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import firebaseApp from '../utils/firebase';
import { sendMail, sendMailBack } from '../utils/email';
// import { useWindowDimensions } from '../utils/hooks';

// const getImage = (index) => {
//   switch (index) {
//     case 0:
//       return (
//         <img src={PlaqadBgHero} alt='presofis_hero_image' className='img' />
//       );
//     case 1:
//       return <img src={BgImg1} alt='presofis_hero_image' className='img1' />;
//     case 2:
//       return <img src={BgImg2} alt='presofis_hero_image' className='img2' />;
//     default:
//       return <img src={BgImg} alt='presofis_hero_image' className='img' />;
//   }
// };

const ModalDisplay = ({ position = 234, onClose = () => null }) => {
  return (
    <div className='modal'>
      <div className='modal-content'>
        <button className='modal-close' onClick={onClose} />
        <img src={SupriseImg} alt='suprise' />
        <h1 className='modal-head'>Oh, Congrats!</h1>
        <p className='modal-number-text'>
          You’re currently
          <span>#{position}</span>
          on the waitlist.
        </p>
        <p className='modal-text'>
          We will send you an email to be one of our early users as soon as
          PresOfis is ready.
        </p>
        {/* <button className='modal-button'>Share with Friends</button> */}
      </div>
    </div>
  );
};

// const domains = ['gmail.com', 'yahoo.com', 'icloud.com', 'outlook.com'];

// Firestore set up
const db = getFirestore(firebaseApp);

// collections
const usersCollection = collection(db, 'users');
const rankingDocRef = doc(db, 'ranking', 'ranking');

// queries
const rankingQuery = query(rankingDocRef);

const userEmailQuery = (email) =>
  query(usersCollection, where('email', '==', email));

function HomePage() {
  const [isOpen, setIsOpen] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const [nameValue, setNameValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [position, setPosition] = useState(0);
  // const { height, width } = useWindowDimensions();
  // const [showHeader, setShowHeader] = useState(true);

  // useScrollPosition(({ currPos }) => {
  //   console.log(width);
  //   if (width <= 400) {
  //     setShowHeader(true);
  //   } else {
  //     setShowHeader(Math.abs(currPos.y) <= height + 10);
  //   }
  // });

  // const [imgIndex, setImgIndex] = useState(0);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setImgIndex((imgIndex) => (imgIndex + 1) % 3);
  //   }, 5000);
  //   return () => clearInterval(interval);
  // }, []);

  const handleSubmit = async () => {
    try {
      if (nameValue.length < 3) {
        return toast.error('Please enter your first name.', { type: 'error' });
      }

      if (!emailValue.length || !emailValue.includes('@')) {
        return toast('Please provide a valid email address', {
          type: 'error',
        });
      }

      // if (domains.includes(emailValue.toLowerCase().split('@')[1])) {
      //   return toast('Please provide a valid business email address', {
      //     type: 'error',
      //   });
      // }
      setIsLoading(true);
      const emailExists = await getDocs(
        userEmailQuery(emailValue.toLowerCase())
      );

      if (emailExists.size > 0) {
        setIsLoading(false);
        return toast('Email already on the list', {
          type: 'error',
        });
      }

      const oldRankingRef = await getDoc(rankingQuery);
      const oldRanking = oldRankingRef.data()?.position || 0;
      const newRanking = oldRanking + 1;

      if (oldRanking >= process.env.REACT_APP_MAX_RANKING) {
        setIsLoading(false);
        return toast('Sorry, we are full', {
          type: 'error',
        });
      }

      await addDoc(usersCollection, {
        email: emailValue.toLowerCase(),
        name: nameValue,
        position: newRanking,
      });

      await setDoc(rankingDocRef, {
        position: newRanking,
      });

      await sendMail(emailValue, nameValue);
      await sendMailBack(emailValue, nameValue);

      setEmailValue('');
      setNameValue('');
      setPosition(newRanking);
      setIsOpen(true);
      setIsLoading(false);
    } catch (error) {
      console.log('error', error);
      setIsLoading(false);
    }
  };

  return (
    <>
      {/* {showHeader && ( */}
      <div className='hero'>
        {/* Nav start */}
        <div className='nav'>
          <div className='logo'>
            <img src={Logo} alt='logo' />
          </div>
        </div>
        <div className='hold'>
          <div className='hero-content'>
            {/* Hero start */}
            <div className='hero-content-container' id='things'>
              {/* Pill */}
              <div className='pill'>
                <div className='pill-inner'>Launching Soon</div>
                <div>
                  Your Personal Press Office
                  <img src={Rocket} alt='rocket' />
                </div>
              </div>

              <h1 className='hero-title'>
                Media Relations on <span className='hero-grad'>Steroids.</span>
                {/* It’s like a <span className='hero-grad'>PR agency</span> on
                  steroids. */}
              </h1>
              <h2 className='hero-subtitle'>
                <span>Pres|Ofis</span> is Plaqad’s community where content
                creators, agencies, brands and publicists can co-create with
                journalists, bloggers, and influencers in an environment of
                trust and mutual benefit.
              </h2>
              <div className='hero-inputs'>
                <div className='hero-inputs-groups'>
                  <input
                    disabled={isLoading}
                    placeholder='Enter your first name'
                    value={nameValue}
                    onChange={(e) => setNameValue(e.target.value)}
                  />
                  <input
                    disabled={isLoading}
                    placeholder='Enter your email address'
                    value={emailValue}
                    onChange={(e) => setEmailValue(e.target.value)}
                  />
                </div>

                <button disabled={isLoading} onClick={handleSubmit}>
                  <span>Join Waitlist</span>
                  <img src={isLoading ? Spinner : HandPoint} alt='point' />
                </button>
              </div>
              <div className='hero-live'>
                Let's keep you updated when we are <span>LIVE</span>
              </div>
            </div>
            <div className='hero-img-container'>
              <div className='hero-image'>
                <img
                  src={PlaqadBgHero}
                  alt='presofis_hero_image'
                  className='img'
                />
                <img
                  src={PlaqadBgPhone}
                  alt='plaqad_hero_mobile'
                  className='sm_img'
                />
                <img
                  className='edge right'
                  src={StarOutline}
                  alt='star-outline'
                />
                <img
                  className='edge left'
                  src={StarOutline}
                  alt='star-outline'
                />
              </div>
            </div>
          </div>
        </div>

        {/* <div className='hero-foot'>
          <span>Know what’s happening </span>
          <img src={LogoBlue} alt='logo' />
          <span>Chat with Journalists and influencers</span>
          <img src={LogoBlue} alt='logo' />
        </div> */}
      </div>
      {/* )} */}
      <div>
        {/* features */}
        <div className='features'>
          <h2>So What Do We Have?</h2>
          <p className='sub'>
            A reliable technology partner for PR managers, agencies, clients,
            and consultants to speed up the media relations and press office
            workflow. Every feature makes media relations and communications
            better, faster, smarter, and sexier.
          </p>
          <div className='cards'>
            <div className='card'>
              <div className='card-icon'>
                <img src={StatsupBlue} alt='stats up' />
              </div>
              <h3>Media measurement</h3>
              <p>
                PR professionals are increasingly determined to measure what
                matters, and we are here to help.
              </p>
            </div>
            <div className='card'>
              <div className='card-icon green'>
                <img src={ChartBlue} alt='chart' />
              </div>
              <h3>Competitor tracking</h3>
              <p>
                Gain powerful insights about your competitors in a more agile
                way. Keep an eye on happenings in the marketplace, especially
                within your niche.
              </p>
            </div>
            <div className='card'>
              <div className='card-icon pink'>
                <img src={TextBlue} alt='chart' />
              </div>
              <h3>Content management system</h3>
              <p>
                Whether you are sending news stories or visual content, our
                friendly CMS gives you the flexibility to create and customize
                content that performs well - in full colour.
              </p>
            </div>
            <div className='card'>
              <div className='card-icon purple'>
                <img src={WaveBlue} alt='wave' />
              </div>
              <h3>Sentiment analysis</h3>
              <p>
                Every piece of coverage matters. Pres|Ofis can determine not
                just tonality, but actual sentiments. And now, it no longer has
                to be manual. How about that?
              </p>
            </div>
            <div className='card'>
              <div className='card-icon red'>
                <img src={StatusBlue} alt='status' />
              </div>
              <h3>Monitor industry news</h3>
              <p>
                A reliable feed on trends, topics and news to help you avoid
                clutter, cut through all the noise and focus on what matters to
                your brand and business.
              </p>
            </div>
            <div className='card'>
              <div className='card-icon blue'>
                <img src={LayerBlue} alt='layer' />
              </div>
              <h3>Content syndication</h3>
              <p>
                Reach out to journalists, bloggers, editors and newsrooms
                through our intelligent distribution service. Explore a better
                way to personalize your outreach.
              </p>
            </div>
          </div>
        </div>

        {/* case */}
        <div className='cases'>
          <div className='case-header'>
            <h2>PR in your Hands.</h2>
          </div>

          <div className='case-box'>
            <div className='left-box'>
              <img src={LadyImg} alt='case' />
              <img src={BannerImg} alt='case' className='hang' />
            </div>
            <div className='right-box'>
              <div className='card'>
                <div className='card-icon'>
                  <img src={PhoneImg} alt='stats up' />
                </div>
                <h3>Media Kits</h3>
                <p>
                  Use Pres|Ofis to build media kits containing all the assets
                  needed to distribute a press release to media contacts.
                </p>
              </div>
              <div className='card blue'>
                <div className='card-icon'>
                  <img src={SmileImg} alt='chart' />
                </div>
                <h3>For Collaboration</h3>
                <p>
                  Collaborate with colleagues on a document or campaign in
                  real-time or asynchronously. Manage and track contact
                  information and actions/conversations with the media.
                </p>
              </div>
              <div className='card pink'>
                <div className='card-icon'>
                  <img src={LaptopImg} alt='chart' />
                </div>
                <h3>Measure Impact</h3>
                <p>
                  Gain access to vital metrics such as online readership,
                  coverage/reach, shares, media value and more.
                </p>
              </div>
              <div className='card gold'>
                <div className='card-icon'>
                  <img src={BarchartImg} alt='wave' />
                </div>
                <h3>Engagement Tracking</h3>
                <p>
                  Follow up on syndicated content with your community of media
                  and directly communicate with your contacts by DM.
                </p>
              </div>
              <div className='card orange'>
                <div className='card-icon'>
                  <img src={NewsImg} alt='status' />
                </div>
                <h3>Campaign Strategy and Set-Up</h3>
                <p>
                  With Pres|Ofis, you have all the tools to collaborate with
                  your team on a strategy, create an in-site deck to document
                  and preview your strategy before going live.
                </p>
              </div>
              <div className='card bluer'>
                <div className='card-icon'>
                  <img src={TabsImg} alt='layer' />
                </div>
                <h3>Faster and Effective Distribution</h3>
                <p>
                  Provide bespoke and up-to-date information to the media about
                  breaking news, issues and events at a faster rate, using our
                  intelligent distribution service.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* story */}
        <div className='story'>
          <div>
            <h2>
              How to get started on <br /> <span>Pres|Ofis</span>
            </h2>
          </div>
          <div className='story-bottom'>
            <div className='cards'>
              <div className='card'>
                <div className='card-icon'>
                  <img src={TaskYellow} alt='task yellow' />
                </div>
                <h3>Create your content</h3>
                <p>
                  Write, edit, and publish your content. You’re in full control
                  .
                </p>
              </div>
              <div className='card'>
                <div className='card-icon pink'>
                  <img src={StatusYellow} alt='status pink' />
                </div>
                <h3>Find journalists</h3>
                <p>
                  Search our media database for journalists, bloggers,
                  influencers, or media platforms. You can even securely upload
                  your own list or invite friends to join you. You might even be
                  able to DM 😄
                </p>
              </div>
              <div className='card'>
                <div className='card-icon green'>
                  <img src={MonitorYellow} alt='monitor green' />
                </div>
                <h3>Pitch the media</h3>
                <p>
                  Be confident that your pitch delivers to the right inbox. And
                  use our tips and tools to make sure you hear back, and secure
                  coverage.
                </p>
              </div>
              <div className='card'>
                <div className='card-icon blue'>
                  <img src={ChartYellow} alt='chart blue' />
                </div>
                <h3>Analyze your impact</h3>
                <p>
                  Convert data into actionable insights. Get the key insights
                  into the performance of your newsroom, stories, and emails.
                  Measure results, define improvement points and demonstrate
                  ROI.
                </p>
              </div>
            </div>

            {/* <button>
              <span>Speak With Us</span>{' '}
              <img src={Headphone} alt='microphone' />
            </button> */}
          </div>
        </div>
      </div>
      <div className='footer'>
        <div className='box'>
          <div className='box-head'>
            <img className='around' src={RoundText} alt='round text' />{' '}
            <img src={Smiley} alt='smiley' />
          </div>
          <img
            className='edge top-right'
            src={StarOutline}
            alt='star-outline'
          />
          <img
            className='edge bottom-left'
            src={StarOutline}
            alt='star-outline'
          />
          <h2>Join the waitlist to get started</h2>
          <p>Enter your email and be the first to know when we go live.</p>
          <div className='footer-inputs'>
            <AnchorLink href='#things'>
              <button disabled={isLoading}>
                <span>Join Waitlist</span>
                <img src={isLoading ? Spinner : HandPoint} alt='point' />
              </button>
            </AnchorLink>
          </div>

          <div className='footer-list'>
            <div className='list-item'>
              <img src={StarCheck} alt='Star Check' />
              <span>No contracts</span>
            </div>
            <div className='list-item'>
              <img src={StarCheck} alt='Star Check' />
              <span>No hidden fees</span>
            </div>
            <div className='list-item'>
              <img src={StarCheck} alt='Star Check' />
              <span>No bullshit</span>
            </div>
          </div>
        </div>
        <div className='logo'>
          <img src={Logo} alt='logo' />
        </div>

        <div className='address'>
          <span style={{ fontWeight: 'bold' }}>Nigeria:</span>
          <br />
          32 Community Rd, Allen, Ikeja, Nigeria.
          <br />
          Tel: +234 7000PLAQAD
        </div>
        <br />
        <div className='address'>
          <span style={{ fontWeight: 'bold' }}>United States:</span>
          <br />
          300 Delaware Ave. Suite 210 Wilmington, DE, US 19801.
          <br />
          Tel: +1 302 396 9651
        </div>
        <div className='socials'>
          {/* <a href='/'>
              <img src={Facebook} alt='Facebook' />
            </a> */}
          <a
            href='https://instagram.com/plaqadinc'
            target='_blank'
            rel='noreferrer'>
            <img src={Instagram} alt='Instagram' />
          </a>
          <a
            href='https://twitter.com/plaqadinc'
            target='_blank'
            rel='noreferrer'>
            <img src={Twitter} alt='Twitter' />
          </a>
        </div>

        <div className='copyright'>
          © {new Date().getFullYear()} Plaqad Inc. All Rights Reserved.
        </div>
      </div>

      {isOpen && (
        <ModalDisplay onClose={() => setIsOpen(false)} position={position} />
      )}
    </>
  );
}

export default HomePage;
